import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import Panorama from './Panorama';
import {ExpandToWindowBottom} from '../common/ExpandToWindowBottom';
import {SimilarListingsGrid} from './SimilarListingsGrid';
import ListingNavigation from './ListingNavigation';
import ListingMap from './ListingMap';
import {ListingTabView, ListingTabViewItem} from './ListingTabView';
import ListingDetailDescription from './ListingDetailDescription';
import {OpenHouseBar} from './OpenHouseBar';
import AgentContactForm from '../agents/AgentContactForm';
import {shouldSendToOpcity} from '../../../lib/lead_routing';
import {PageContext} from '../../../lib/pageContext';

class ListingDetail extends React.Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
    agent: PropTypes.object,
    broker: PropTypes.object,
    sov: PropTypes.shape({
      id: PropTypes.string,
      agent: PropTypes.shape({}),
      broker: PropTypes.shape({}),
    }),
  };

  state = {};

  render() {
    const {listing, agent, broker, sov} = this.props;

    const showAgentContactForm =
      listing.isActive() &&
      (sov?.agent ||
        sov?.broker ||
        shouldSendToOpcity({listing, agent, broker, sov}) ||
        broker?.email ||
        agent?.email);

    const latLng = listing.getLatLng();

    return (
      <div ref={(node) => (this.domNode = node)} className="ListingDetail">
        <ListingNavigation listing={listing}>
          {showAgentContactForm && (
            <div className="ListingDetail_contact">
              <AgentContactForm
                triggerOpen={this.state.triggerOpen}
                listing={listing}
                agent={agent}
                broker={broker}
              />
            </div>
          )}
        </ListingNavigation>

        {listing.getUpcomingOpenHouseItems().length > 0 && (
          <>
            <OpenHouseBar listing={listing} />
            <div className="ListingDetail_afterOpenHouseBar" />
          </>
        )}

        <ListingTabView
          items={[
            <ListingTabViewItem default title="About this listing">
              <div className="ListingDetail_panorama">
                <Panorama listing={listing} />
              </div>

              <div className="ListingDetail_body">
                <ListingDetailDescription
                  listing={listing}
                  agent={agent}
                  broker={broker}
                  onShowContactForm={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth',
                    });
                    this.setState({triggerOpen: true}, () =>
                      setTimeout(() => this.setState({triggerOpen: false}), 500)
                    );
                  }}
                />
              </div>
            </ListingTabViewItem>,

            latLng != null && (
              <ListingTabViewItem title="Map">
                <ExpandToWindowBottom
                  minHeight={600}
                  child={(height) => <ListingMap listing={listing} height={height} />}
                />
              </ListingTabViewItem>
            ),

            <ListingTabViewItem title="Similar Listings">
              <div className="ListingDetail_similars">
                <SimilarListingsGrid listing={listing} />
              </div>
            </ListingTabViewItem>,
          ]}
        />
      </div>
    );
  }
}

const ListingDetailWithPageContext = (props) => {
  const {sov} = useContext(PageContext);
  return <ListingDetail {...props} sov={sov} />;
};

export default ListingDetailWithPageContext;
