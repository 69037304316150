import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {urlForSearch} from '../../lib/support/routing';
import ListingQuery, {
  ListingSearchResultFacets,
  ListingSearchResultSet,
} from '../../lib/search/ListingQuery';
import Button from './common/Button';
import ListingFilterBar from './search/filters/ListingFilterBar';
import {ListingResults} from './listings/ListingResults';
import {zeus} from '../../lib/zeus';
import {searchAnalyticsParams} from '../../lib/analytics/googleAnalytics';
import {connect} from 'react-redux';
import {analyticsEvent} from '../../lib/actions';

class ListingSearch extends React.Component {
  static propTypes = {
    query: PropTypes.instanceOf(ListingQuery).isRequired,
    initialResultSetReady: PropTypes.bool,
    resultSet: PropTypes.instanceOf(ListingSearchResultSet),
    facets: PropTypes.instanceOf(ListingSearchResultFacets).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    router: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {query, initialResultSetReady, resultSet, facets} = this.props;
    return (
      <div className="ListingSearch">
        <div className="ListingSearch_filters">
          <ListingFilterBar
            query={query}
            facets={facets}
            onUpdateQuery={this._updateQuery}
            onResetQuery={this._resetQuery}
          />
          {query.get('agent_uid') && (
            <div className="ListingSearch_agent">
              <label>Filtering by agent</label>
              <span className="ListingSearch_agent_agent">…</span>
              <Button
                label="Clear"
                onClick={() => this._updateQuery((q) => q.set({agent_uid: null}))}
              />
            </div>
          )}

          {query.get('broker_uid') && (
            <div className="ListingSearch_broker">
              <label>Filtering by broker</label>
              <span className="ListingSearch_broker_broker">…</span>
              <Button
                label="Clear"
                onClick={() => this._updateQuery((q) => q.set({broker_uid: null}))}
              />
            </div>
          )}
        </div>

        {initialResultSetReady ? (
          <div className="ListingSearch_search">
            <ListingResults
              query={query}
              inflight={!initialResultSetReady}
              resultSet={resultSet}
              onUpdateQuery={this._updateQuery}
            />
          </div>
        ) : (
          <div className="ListingSearch_search_placeholder"></div>
        )}
      </div>
    );
  }

  _resetQuery = () => {
    const query = this.props.query.clone();
    query.reset();
    if (this.props.query.toQueryString() !== query.toQueryString()) {
      window.location.href = urlForSearch(query);
    }
  };

  _updateQuery = (fn, options = null) => {
    const query = this.props.query.clone();
    fn(query);
    if (this.props.query.toQueryString() !== query.toQueryString()) {
      // only snap back to the first page when the URL part of the query changed,
      // our location component adds extra parameters to the query
      query.setPage(1);
    }

    const search = query.toQueryString();
    if (this.props.router.location.search !== search) {
      this.props.dispatch(analyticsEvent('search_change', searchAnalyticsParams(search)));
      this.props.router.replace(urlForSearch(query));
    }
  };
}

const ListingSearchWithFacets = (props) => {
  const {query, initialQueryReady} = props;
  const [facets, setFacets] = useState(new ListingSearchResultFacets());
  useEffect(() => {
    if (!initialQueryReady) {
      return;
    }
    let cancelled = false;
    (async () => {
      const queryObj = Object.assign(query.toGraphQL(), {facets: {include: true}});
      const data = await zeus(`/api/query/facets`, {queryObj});
      if (!cancelled) {
        const f = ListingSearchResultFacets.fromObject(data);
        setFacets(f);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [query, initialQueryReady]);
  return <ListingSearch {...props} facets={facets} />;
};

export default connect()(withRouter(ListingSearchWithFacets));
